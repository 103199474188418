import Component from '../classes/Component';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {$dom} from '../helpers/dom';
import variables from '../variables';

gsap.registerPlugin(ScrollTrigger);

const {each} = $dom;
const duration = variables.gsapDefaultDuration;

export default function() {

	return new Component({
		name: 'sectionAppearAnimation',
		requiredTargets: variables.selectors.animatedSection,
		onInit() {
			each(this.targets, section => {
				gsap.from(section, {
					autoAlpha: 0,
					ease: 'expo.out',
					duration: duration * 2,
					scrollTrigger: {
						trigger: section,
						start: 'top 75%',
						markers: false
					}
				})
			})
		}
	})
}
