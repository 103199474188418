import Component from '../classes/Component';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {$dom} from '../helpers/dom';
import variables from '../variables';

gsap.registerPlugin(ScrollTrigger);

const {callAll, exist} = $dom;
const
	sectionSelector = '.js-page-animation--intro',
	rateSelector = '.js-page-animation--intro-rate',
	rateNotebookSelector = '.js-page-animation--intro-notebook-rate',
	toUpEase = 'slow(0.1, 2, true)',
	toDownEase = 'bounce.out',
	duration = variables.gsapDefaultDuration
;

export default function() {

	return new Component({
		name: 'introAnimation',
		requiredTargets: [sectionSelector, rateNotebookSelector],
		onInit() {
			const tl = gsap.timeline({
				repeat: -1,
				scrollTrigger: {
					trigger: sectionSelector,
					toggleActions: 'restart pause resume none',
					markers: false
				},
			});
			tl
				.to(rateNotebookSelector, {duration, ease: toUpEase, y: -30})
				.to(rateNotebookSelector, {duration, ease: toDownEase, y: 0});

			if (exist(rateSelector)) {
				callAll(rateSelector, rate => {
					tl
						.to(rate, {duration: duration / 2, ease: toUpEase, y: '-50%'})
						.to(rate, {duration: duration, ease: toDownEase, y: 0}, `-=${duration / 3}`)
				})
			} else {
				tl.repeatDelay(duration * 4)
			}
		}
	})
}
