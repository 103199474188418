import Component from '../classes/Component';
import IMask from 'imask';

export default function phoneMask(mask = '+{7} (000) 000-00-00') {

	const initMasks = targets => targets.map(target => ({
			target,
			mask: IMask(target, {mask})
		})
	);

	return new Component({
		name: 'phoneMask',
		requiredTargets: '.js-phone-mask',
		onInit() {
			this.masks = initMasks(this.targets);
		},
		onDestroy() {
			this.masks = this.masks.filter(({mask}) => {
				Boolean(mask.destroy) && mask.destroy();
				return false;
			});
		}
	})
}
