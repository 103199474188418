import Component from '../classes/Component';
import { gsap } from "gsap";
import {$dom} from '../helpers/dom';
import variables from '../variables';

const {getAll, attr} = $dom;

const rootSelector = '#js-preloader';
const svgSelector = `${rootSelector} svg`;
const pathSelector = `${rootSelector} path`;
const storageKey = 'pl-animation-payed';

export default function preloader(duration = .5, ease = 'slow(0.2, 0.7, false)') {

	const alreadyPlayed = localStorage.getItem(storageKey) === 'true';

	const animation = gsap.timeline({
		paused: true,
		delay: alreadyPlayed ? 0 : duration,
		onComplete() {
			localStorage.setItem(storageKey, 'true')
		}
	});

	const buildAnimation = () => {
		const pathLength = getAll(pathSelector).length;

		if (!alreadyPlayed) {
			animation
				.fromTo(pathSelector, {
					y: 20,
					autoAlpha: 0
				}, {
					y: 0,
					autoAlpha: 1,
					duration,
					stagger: {
						each: duration / pathLength
					}
				}, 'in')
				.to(pathSelector, {
					y: 20,
					autoAlpha: 0,
					duration: duration / 2,
					delay: duration,
					stagger: {
						each: duration / pathLength
					}
				})
		}


		return animation
			.to(svgSelector, {
				y: -50,
				autoAlpha: 0,
				duration: duration * 1.5,
				ease
			}, 'appear-end')
			.to(rootSelector, {
				autoAlpha: 0,
				duration: duration * 1.5,
				ease
			}, 'appear-end')
			.fromTo(variables.dom.wrapper, {
				autoAlpha: 0
			}, {
				autoAlpha: 1,
				duration: duration * 1.5
			}, 'appear-end');
	};

	const $Page = this;

	return new Component({
		name: 'preloader',
		requiredTargets: ['#js-preloader', '#js-preloader path'],
		onInit() {
			attr($Page.rootEl, 'data-preloader-enabled') === 'true' && buildAnimation().play();
		},
		onDestroy() {

		}
	})
}
