import Component from '../classes/Component';
import {$events} from '../helpers/events';
import {$dom} from '../helpers/dom';
import {$style} from '../helpers/style';
import variables from '../variables';

const {text, addClass, html, clone, append, createElement, get} = $dom;

const parentSelector = '.js-modal-documents--parent';
const outputSelector = '.js-modal-documents--output';
const outputItemSelector = '.js-modal-documents--output-item';
const outputItemWrapClassName = 'about__documents-box';
const outputItemIconClassName = 'about__documents-box-icon';
const rootUrl = 'https://lk.panfilovinvest.ru';
const apiUrl = `${rootUrl}/api/v1/documents-company`;

export const configureAssetPath = attachment  => {
	if (!attachment) return null;

	if (Object.keys(attachment).length > 0 && attachment.hasOwnProperty('uid') && attachment.hasOwnProperty('path') && attachment.hasOwnProperty('extension'))
		return `${rootUrl}/${attachment.path}/${attachment.uid}${attachment.extension}`;

	return null;
};

export default function modalDocuments() {
	const $Page = this;
	let loaded = false;

	const update = async () => await fetch(apiUrl, {
		mode: 'cors'
	}).then((response) => response.json());
	
	const handleModalOpen = () => !loaded && update()
		.then(response => {
			console.log('ddddddddd');
			printDocuments(response);
			loaded = true
		})
		.catch(e => {
			text(parentSelector, `Ошибка загрузки документов: ${e}`);
			addClass(parentSelector, 'text-danger');
			$style.set(parentSelector, 'min-height', 'initial')
		})
	;
	const printDocuments = documents => {
		const outputItem = clone(get(outputItemSelector));
		html(outputSelector, '');

		documents.forEach(({attachment, title}) => {
			if (attachment) {
				const wrap = createElement('a', {
					class: outputItemWrapClassName,
					target: '_blank',
					href: configureAssetPath(attachment)
				});

				const span = createElement('span');

				const icon = `<span class="${outputItemIconClassName}"><svg class="svg-icon"><use xlink:href="${$Page.spritePath}sprite.svg#document"></use></svg></span>`;
				const clonedOutput = clone(outputItem);

				html(span, title);
				html(wrap, icon);

				append(wrap, span);
				append(clonedOutput, wrap);
				append(outputSelector, clonedOutput)
			}
		});

		addClass(parentSelector, variables.classNames.lazyLoaded)
	};

	return new Component({
		name: 'modalDocuments',
		requiredTargets: [parentSelector, outputSelector],
		onInit() {
			this.update = update;
			
			console.log('jfksdjdkjdfhj');

			handleModalOpen();

			// $events.delegate.on('shown.bs.modal', window, handleModalOpen)
		},
		onDestroy() {
			// $events.delegate.off('shown.bs.modal', window, handleModalOpen)
		}
	})
}
