import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';

const {get, addClass, removeClass} = $dom;

const rootSelector = '.js-cookiebanner';
const bannerButtonSelector = '.js-cookiebanner-button';

export function cookiebanner() {
  let storageKey = 'cookie-is-accepted';

  return new Component({
    name: 'cookiebanner',
    requiredTargets: rootSelector,
    onCreate() {
      this.cookieIsAccepted = localStorage.getItem(storageKey) === 'true';
    },
    onInit() {
      if (this.cookieIsAccepted) return

      addClass(get(rootSelector), 'shown');

      $events.delegate
        .on('click', bannerButtonSelector, () => {
          this.cookieIsAccepted = true;
          
          localStorage.setItem(storageKey, this.cookieIsAccepted)

          removeClass(get(rootSelector), 'shown');
        })

    },
    onDestroy() { }
  })
}