import Component from '../classes/Component';
import {gsap} from 'gsap';
import {$dom} from '../helpers/dom';
import variables from '../variables';
import {$style} from '../helpers/style';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {debounce, isElement} from '../helpers/_utilities';

gsap.registerPlugin(ScrollTrigger);

const {get, getAll, each, addClass, removeClass} = $dom;
const {offset} = $style;

const wrapperSelector = '.js-page-animation--line-wrapper';
const rootLineSelector = '.js-page-animation--line';
const lineSelector = '.js-page-animation--line';
const stepSelector = '.js-page-animation--line-step';
const sectionSelector = '#js-how-it-works-section';
const curtainSelector = '.js-page-animation--line-curtain';
const headSelector = '.js-page-animation--line-head';
const enableJustOneActiveStep = false;

function getRightOffset(element) {
	return window.innerWidth - element.getBoundingClientRect().right
}

export default function() {

	let setLinePosition = (step) => {
		const
			main = get(wrapperSelector),
			line = get(lineSelector),
			firstStep = step[0],
			lastStep = step[step.length - 1]
		;

		gsap.set(line, {clearProps: 'with,left,top,height'});

		const ww = variables.windowWidth;

		if (variables.windowWidth >= variables.breakpoints.md) {
			const
				circleLeftOffset = offset(firstStep).left,
				circleRightOffset = ww - (offset(lastStep).left + lastStep.offsetWidth),
				lineLeftPosition = circleLeftOffset - offset(main).left + (firstStep.offsetWidth / 2)
			;

			gsap.set(line, {
				width: ww - (circleLeftOffset + circleRightOffset) - firstStep.offsetWidth,
				left: lineLeftPosition,
				top: firstStep.offsetHeight / 2
			})
		} else {
			const
				lineTopOffset = offset(firstStep).top - offset(main).top + firstStep.offsetHeight / 2
			;

			gsap.set(line, {
				height: main.offsetHeight - lineTopOffset * 2,
				top: lineTopOffset,
				right: getRightOffset(firstStep) - getRightOffset(main) + firstStep.offsetWidth / 2 - line.offsetWidth
			})
		}
	};

	const createAnimation = (highLightCallback) => {
		const animation = gsap.timeline({
			scrollTrigger: {
				trigger: sectionSelector,
				start: 'center center',
				markers: false,
				scrub: true,
				invalidateOnRefresh: true,
				pin: true,
				onUpdate: highLightCallback
			}},
		);

		if (variables.windowWidth >= variables.breakpoints.md) {
			animation
				.to(curtainSelector, {width: 0}, 'movement')
				.to(headSelector, {left: `calc(100% - ${get(headSelector).offsetWidth}px)`}, 'movement')
		} else {
			animation
				.to(curtainSelector, {height: 0}, 'movement')
				.to(headSelector, {top: `calc(100% - ${get(headSelector).offsetHeight}px)`}, 'movement')
		}

	};
	const onScrollHandler = debounce(scrollTrigger => {
		const isDesktop = variables.windowWidth >= variables.breakpoints.md;

		const targetSizeAxis = isDesktop ? 'width' : 'height';
		const targetDirectionAxis = isDesktop ? 'left' : 'top';
		const targetProgressValue = gsap.getProperty(rootLineSelector, targetSizeAxis) - gsap.getProperty(curtain, targetSizeAxis);
		const firstStep = getAll(stepSelector)[0];
		const targetWrapOffset = offset(wrapper)[targetDirectionAxis];
		const targetFirstStepOffset = offset(firstStep)[targetDirectionAxis];
		let currentStep = null;


		each(steps, step => {
			const currentStepOffsetDifference = offset(step)[targetDirectionAxis] - targetWrapOffset;
			const firstStepOffsetDifference = targetFirstStepOffset - targetWrapOffset;

			if (
				(targetProgressValue >= currentStepOffsetDifference - firstStepOffsetDifference - step[isDesktop ? 'offsetWidth' : 'offsetHeight'] / 2)
				&& scrollTrigger.progress > 0
			) {
				addClass(step, variables.classNames.active);
				currentStep = step
			} else {
				!enableJustOneActiveStep && removeClass(step, variables.classNames.active)
			}
		});

		if (currentStep && enableJustOneActiveStep) {
			const notActiveSteps = steps.filter(s => s !== currentStep);

			if (notActiveSteps.length > 0 && notActiveSteps.every(e => isElement(e))) removeClass(notActiveSteps, variables.classNames.active)
		}

	}, 0);

	const $Page = this;

	return new Component({
		name: 'stepsAnimation',
		requiredTargets: [lineSelector, stepSelector, wrapperSelector, sectionSelector],
		onInit() {
			//const curtain = get(curtainSelector);
			const steps = getAll(stepSelector);
			//const wrapper = get(wrapperSelector);

			setLinePosition = setLinePosition.bind(null, steps);

			$Page.addResizeDependentMethod(setLinePosition);

			// createAnimation(onScrollHandler)
		},
		onDestroy() {

		}
	})
}
