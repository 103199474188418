import Page from '../../classes/Page';
import setMainHeight from './mainHeight';

const homePage = new Page({
	name: 'home',
	rootElementId: 'js-page-home',
	debug: false,
	onInit() {
		this.addResizeDependentMethod(setMainHeight);
	}
});

export default homePage;
