import Page from '../classes/Page';
import introAnimation from '../animations/intro';
import sectionAppearAnimation from '../animations/sectionAppear';
import sectionCirclesAnimation from '../animations/sectionCircles';
import stepsAnimation from '../animations/steps';

const regularPage = new Page({
  name: 'regular',
  rootElementId: 'js-page-regular',
	debug: false,
	onInit() {
		this
			.addComponent(introAnimation)
			.addComponent(sectionAppearAnimation)
			.addComponent(sectionCirclesAnimation)
			.addComponent(stepsAnimation)
	}
});

export default regularPage;
