import Component from '../classes/Component';
import variables from '../variables';
import {$dom} from '../helpers/dom';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {$events} from '../helpers/events';
import {debounce, isElement} from '../helpers/_utilities';

gsap.registerPlugin(ScrollTrigger);

const {each, getAll, exist} = $dom;

const circleSelector = '.js-page-animation--section-circle';
const duration = variables.gsapDefaultDuration * 10;

export default function() {

	let animations = [];

	const createAnimation = (section, circles) => {
		return gsap
			.timeline({
				yoyo: true,
				repeat: -1,
				scrollTrigger: {
					trigger: section,
					start: 'center bottom',
					toggleActions: 'restart pause resume none',
					markers: false
				}
			})
			.to(circles, {
				rotate: 360,
				stagger: duration / 4,
				duration,
				ease: 'slow(0.1, 0.1, false)'
			})
	};

	const updateAnimations = debounce(() => {
		animations.forEach(animation => {
			if (animation.hasOwnProperty('scrollTrigger')) {
				animation.scrollTrigger.refresh()
			}
		})
	}, variables.resizeDebounce);

	const handleLazyLoaded = ({detail}) => {
		const {el} = detail;
		if (isElement(el) && el.closest(variables.selectors.animatedSection)) updateAnimations()
	};


	return new Component({
		name: 'sectionCirclesAnimation',
		requiredTargets: [variables.selectors.animatedSection, circleSelector],
		onInit() {
			this.animations = animations = this.targets
				.filter(section => exist(circleSelector, section))
				.map(section => createAnimation(section, getAll(circleSelector, section)))
			;

			$events.delegate.on(variables.customEventNames.lazyImageLoaded, window, handleLazyLoaded);
		},
		onDestroy() {
			$events.delegate.off(variables.customEventNames.lazyImageLoaded, window, handleLazyLoaded);
		}
	})
}
