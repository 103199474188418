import Component from '../classes/Component';
import {$dom} from '../helpers/dom';
import Waves from 'node-waves'

const {each} = $dom;

export default function buttonWaves() {

	return new Component({
		name: 'buttonWaves',
		requiredTargets: '.js-button-waves',
		onInit() {
			Waves.attach(this.options.requiredTargets);
			Waves.init();
		},
		onDestroy() {}
	})
}
