import variables from '../../variables';
import {isElement} from '../../helpers/_utilities';
import {$dom} from '../../helpers/dom';
import {$style} from '../../helpers/style';

const {get} = $dom;
const {remove, set} = $style;
const {header, footer} = variables.dom;
const {xl, md} = variables.breakpoints;

export default function mainHeight() {
	const mainEl = get('.js-page-home-main');
	const lazyImage = get('.'+variables.classNames.lazyImage, mainEl);
	const ww = variables.windowWidth;
	const targetProp = 'min-height';

	if ([header, footer, mainEl].every(el => isElement(el))) {
		remove(mainEl, targetProp);
		remove(lazyImage, targetProp);
		const availableHeight = variables.windowHeight - (header.offsetHeight + footer.offsetHeight);

		 if (ww >= xl) {
			 set(mainEl, targetProp, `${availableHeight}px`);
		 } else if(ww >= md && ww < xl) {
		 	const mainHeight = mainEl.offsetHeight;

			if (mainHeight > lazyImage.offsetHeight) {
				set(lazyImage, targetProp, availableHeight+'px');
				// console.log('lazy!')
			}
			 set(mainEl, targetProp, availableHeight+'px');
		 	// set(mainEl, 'height', `${variables.windowHeight - (header.offsetHeight + footer.offsetHeight)}px`);
		 }
	}
}
